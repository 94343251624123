"use strict";

var _interopRequireDefault = require("C:/Users/Admin/Documents/185-qlbh-web/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
var _BarHorizontalChart = _interopRequireDefault(require("../BarHorizontalChart"));
var _dashboard = require("@/api/dashboard");
var _moment = _interopRequireDefault(require("moment"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    BarHorizontalChart: _BarHorizontalChart.default
  },
  data: function data() {
    return {
      series: [],
      date: [(0, _moment.default)().subtract(2, "month").format("YYYY-MM"), (0, _moment.default)().format("YYYY-MM")],
      dataChart: []
    };
  },
  created: function created() {
    this.getDataChart();
  },
  methods: {
    convertSeries: function convertSeries() {
      var _this = this;
      var data = this.dataChart.reverse();
      var columnSeri = {
        name: "Total",
        type: "bar",
        itemStyle: {
          color: _variables.default.primaryColor
        },
        yAxisIndex: 0,
        data: data.map(function (item) {
          return item.total;
        })
      };
      this.series = [columnSeri];
      this.$nextTick(function () {
        _this.$refs.chart.initChart();
      });
    },
    getDataChart: function getDataChart() {
      var _this2 = this;
      _dashboard.dashboardApi.productTop({
        top: 10
      }).then(function (res) {
        _this2.dataChart = res.data.map(function (e) {
          return {
            name: e.value.product.name,
            total: e.value.total,
            unit: e.value.product.unit
          };
        });
        _this2.convertSeries();
      });
    }
  }
};
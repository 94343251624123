var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container filter-drawer" }, [
        _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "filter-item" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(_vm._s(_vm.$t("search"))),
                ]),
                _c("el-input", {
                  nativeOn: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      _vm.query.page = 1
                      _vm.fetchData()
                    },
                  },
                  model: {
                    value: _vm.query.search,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "search", $$v)
                    },
                    expression: "query.search",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticClass: "font-bold font-size--medium",
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", size: "medium" },
                on: { click: _vm.handleSync },
              },
              [_vm._v("Sync Shopify Order")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: { data: _vm.data, fit: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Code", "min-width": "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.orderNumber
                      ? _c("label", [_vm._v("#" + _vm._s(row.orderNumber))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "150", label: "Customer" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Name: ")]),
                      _c("span", { staticStyle: { "min-width": "50px" } }, [
                        _vm._v(_vm._s(row.customerName || "*******")),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "150", label: "Shipping Info" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _c("label", [_vm._v("Email: ")]),
                      _c("span", { staticStyle: { "min-width": "50px" } }, [
                        _vm._v(_vm._s(row.email || "********")),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Phone: ")]),
                      _c("span", { staticStyle: { "min-width": "50px" } }, [
                        _vm._v(_vm._s(row.phone || "********")),
                      ]),
                    ]),
                    _c("div", [
                      _c("label", [_vm._v("Address: ")]),
                      _c("span", { staticStyle: { "min-width": "50px" } }, [
                        _vm._v(
                          _vm._s(
                            "" +
                              (row.shippingAddress ? row.shippingAddress : "")
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      [
                        _vm.isExistCrawBtn
                          ? _c(
                              "el-button",
                              {
                                staticClass: " font-bold",
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenShopifyCrawLink(
                                      "https://admin.shopify.com/store/shiratori/orders/" +
                                        row.syncId +
                                        "?crawl=true"
                                    )
                                  },
                                },
                              },
                              [_vm._v("Đồng bộ shopify")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { "min-width": "250px", label: "Products" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return _vm._l(
                    row.shopifyOrderDetails,
                    function (orderDetail) {
                      return _c(
                        "div",
                        {
                          key: orderDetail.id,
                          staticStyle: { "margin-bottom": "10px" },
                        },
                        [
                          _c("div", { staticClass: "space-between" }, [
                            _c("label", [_vm._v(_vm._s(orderDetail.name))]),
                            _c(
                              "span",
                              { staticStyle: { "min-width": "50px" } },
                              [
                                _vm._v(
                                  "x" +
                                    _vm._s(
                                      _vm.$formatNumberVN(orderDetail.quantity)
                                    )
                                ),
                              ]
                            ),
                          ]),
                          orderDetail.discount != 0
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-decoration-line": "line-through",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatNumberVN(orderDetail.price)
                                      ) + "đ"
                                    ),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "padding-left": "10px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$formatNumberVN(
                                          orderDetail.price *
                                            orderDetail.quantity -
                                            orderDetail.discount
                                        )
                                      ) + "đ"
                                    ),
                                  ]
                                ),
                                _c("p", { staticStyle: { margin: "0" } }, [
                                  _vm._v(
                                    "Discount: -" +
                                      _vm._s(
                                        _vm.$formatNumberVN(
                                          orderDetail.discount
                                        )
                                      ) +
                                      "đ"
                                  ),
                                ]),
                              ])
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$formatNumberVN(orderDetail.price)
                                  ) + "đ"
                                ),
                              ]),
                        ]
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Status Link Gift", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.giveaway
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "small",
                            },
                          },
                          [_vm._v(" Linked " + _vm._s(row.giveaway.code) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Status Link Order", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.order
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "small",
                            },
                          },
                          [_vm._v(" Linked " + _vm._s(row.order.code) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Payment Status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.paymentStatus
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", size: "small" } },
                          [_vm._v(" " + _vm._s(row.paymentStatus) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.status
                      ? _c(
                          "el-tag",
                          { attrs: { effect: "dark", size: "small" } },
                          [_vm._v(" " + _vm._s(row.status) + " ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Price", width: "200", align: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Subtotal")]),
                      _c("span", { staticStyle: { "min-width": "50px" } }, [
                        _vm._v(
                          _vm._s(
                            row.totalPrice != 0
                              ? "+ " + _vm.$formatNumberVN(row.totalPrice)
                              : 0
                          ) + "đ"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "space-between" }, [
                      _c("label", [_vm._v("Taxes")]),
                      _c("span", { staticStyle: { "min-width": "50px" } }, [
                        _vm._v(
                          _vm._s(
                            row.moneyTax != 0
                              ? "+ " + _vm.$formatNumberVN(row.moneyTax)
                              : 0
                          ) + "đ"
                        ),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Date created", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatDateFormString(row.createdDate)) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Action", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        !row.order
                          ? _c(
                              "el-button",
                              {
                                staticClass: "font-size--medium font-bold",
                                attrs: { size: "medium", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleLink(row)
                                  },
                                },
                              },
                              [_vm._v("Link Order")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticClass: "font-size--medium font-bold",
                                attrs: { size: "medium", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleShowOrder(row)
                                  },
                                },
                              },
                              [_vm._v("Show Order")]
                            ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        !row.giveaway
                          ? _c(
                              "el-button",
                              {
                                staticClass: "font-size--medium font-bold",
                                attrs: { size: "medium", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleLinkGift(row)
                                  },
                                },
                              },
                              [_vm._v("Link Gift")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticClass: "font-size--medium font-bold",
                                attrs: { size: "medium", type: "primary" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleShowGift(row)
                                  },
                                },
                              },
                              [_vm._v("Show Gift")]
                            ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          pageSizes: [12, 24, 48, 64],
          page: _vm.query.page,
          limit: _vm.query.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.query, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.query, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
      _c("order-modal", {
        ref: "modal",
        attrs: { disabled: false },
        on: { "submit:ok": _vm.fetchData },
      }),
      _c("give-away-modal", {
        ref: "giftModal",
        attrs: { disabled: false, type: _vm.GiveawayType.Giveaway },
        on: { "submit:ok": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }